import { createRouter, createWebHistory } from 'vue-router'
//import Home from '../views/Home.vue'
import { storageService } from '@/services/storage.service'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Page/Login.vue'),
     meta: {
      isPublic: true
    }
  },
  {
    path: '/project-page',
    name: 'ProjectPanel',
    component: () => import('../views/Page/ProjectPanel.vue')
  },
  {
    path: '/system-users',
    name: 'Client',
    component: () => import('../views/Page/Client.vue')
  },
  {
    path: '/panel/:folder',
    component: () => import('../views/Page/Panel.vue'),
    children: [
      {
        path: '',
        name: 'Course',
        component: () => import('../views/Page/Course.vue')
      },
      {
        path: 'topic',
        name: 'Topic',
        component: () => import('../views/Page/Topic.vue')
      },
      {
        path: 'year',
        name: 'Exam Year',
        component: () => import('../views/Page/ExamYear.vue')
      },
      {
        path: 'lecture-note',
        name: 'Lecture Note',
        component: () => import('../views/Page/LectureNote.vue')
      },
      {
        path: 'past-question',
        name: 'Past Question',
        component: () => import('../views/Page/PastQuestion.vue')
      },
      {
        path: 'test-past-question',
        name: 'Test Past Question',
        component: () => import('../views/Page/TestPastQuestion.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( async (to, from, next) =>{
  const isLoggedIn = await storageService.getLoginData()
  const isPublic = to.matched.some(rec => rec.meta.isPublic)
  if(!isLoggedIn && !isPublic){
    return next({
      path: '/'
    })
  }

  next()
})

export default router
