class StorageService{

	loginInfo = 'loginData'

	setApiUrl () {
        //const host = window.location.hostname
        //return `http://${host}:3333/v1/operator-api`
        return 'https://entryapi.lfxedu.com/v1/operator-api'
    }

    async storeLogin (value) {
		const encryptedValue = btoa(escape(JSON.stringify(value)))
		localStorage.setItem(this.loginInfo, encryptedValue)
    }

    async getLoginData () {
		const ret = localStorage.getItem(this.loginInfo)
		if (!ret) {
			return false
		}
		return JSON.parse(unescape(atob(ret)))
    }

    async getToken () {
        return new Promise( resolve => {
            this.getLoginData()
                .then((res) => {
                    resolve(res.token)
                })
                .catch(() => { resolve(true) })
        })
    }

    removeLogin () {
		localStorage.removeItem(this.loginInfo)
    }

 }

const storageService = new StorageService()
export { storageService }